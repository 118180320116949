import React, { Component } from "react"
import MWB_LOADER from "../../assets/images/mwb_loader.png"
import splashLogo from "../../assets/images/rewindlogo.png"
import successAlert from "../../assets/images/successAlert.svg";
import profile from "../../assets/images/Profile.png";
import logotrans from "../../assets/images/logoreans.png";
import { nairaFormat } from "../../utils/helpers";
import { StageSpinner } from "react-spinners-kit";
import { Card, notification, Rate, Radio } from "antd";
import $ from "jquery";
import "../../assets/css/style.css";

import "../../assets/css/responsive.css";
import { userLoggedIn, loginUser, USER_KEY } from "../../utils/auth";
import Endpoint from "../../utils/endpoint";


class Home extends Component {
  state = {
    payLoad: JSON.parse(localStorage.getItem(USER_KEY)),
    foodArr: [],
    drinkArr: [],
    starCount: 0,
    shishaArr: [],
    proteinArr: [],
    grillArr: [],
    starterArr: [],
  };

  openNotification = (err) => {
    notification.error({
      message: <small className="poppins-600">Error</small>,
      description: <small>{err}</small>,
      placement: "topRight",
    });
  };
  clearInputFields() {
    document.getElementById("username").value = "";
    document.getElementById("password").value = "";
    this.setState({ pageLoading: false });
  }
  InitializeUser = () => {
    if (userLoggedIn()) {
      $("#preloader").fadeIn();
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 1000);
      // loginUser(this.state.payLoad?.accessToken, this.state.payLoad, true);
    } else {
      $("#submit__btn").attr("disabled", true);
    }
  };
  urlInitialization = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const emailParams = urlParams.get("email");
    if (emailParams != null) {
      this.setState({
        email: emailParams,
        triggerDisabled: true,
      });
      $("#loginCard").hide();
      $("#registerCard").fadeIn(1500);
    }
  };
  handleInput = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    // this.validateInputFields();
  };

  // validateInputFields = () => {
  //     if ((this.state.username != null && this.state.username.length >= 4) && (this.state.password != null && this.state.password.length >= 4)) {
  //         $("#submit__btn").attr("disabled", false)
  //     }
  //     else {
  //         $("#submit__btn").attr("disabled", true)
  //     }
  // }
  initPage = () => {
    this.InitializeUser();
    setTimeout(() => {
      $("#splash").fadeOut();
    }, 3000);

    setTimeout(() => {
      $("#loginScreen").fadeIn("slow");
      $("#submit__btn").attr("disabled", true);
    }, 3500);
  };

  handleSubmit = () => {
    if (this.state.textrating == null) {
      alert("Input text!");
      return;
    }
    this.setState({ loading: true });
    const payLoad = {
      Ratings: this.state.textrating,
      Name: this.state.fullname,
      table: this.state.tnumber,
    };
    Endpoint.createRatings(payLoad.Name, 0, payLoad.Ratings, payLoad.table)
      .then((res) => {
        console.log(res);
        this.setState({ loading: false });
        this.setState({
          textrating: "",
          fullname: "",
          tnumber: "",
        });
        // $("#tnumber").val("")
        alert("We have recieved your comments. Thank you for patronizing us!");

        this.toggleBack();
        // window.location.reload(true)
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handleCreateAccount = () => {
    if (
      this.state.username == null ||
      this.state.password == null ||
      this.state.phone_number == null ||
      this.state.email == null
    ) {
      this.openNotification("Fields presented are not to be left empty");
      return;
    }
    $("#preloader").fadeIn();
    const payLoad = {
      username: this.state.username,
      email: this.state.email,
      password: this.state.password,
      phoneNumber: this.state.phone_number,
    };
    console.log(this.state);
    Endpoint.signup(payLoad)
      .then((res) => {
        console.log(res.data);
        $("#preloader").fadeOut();
        $("#alert__back").fadeIn();
        this.setState({
          resPayload: res.data,
        });

        loginUser(res.data.accessToken, res.data, false);
      })
      .catch((err) => {
        $("#preloader").fadeOut();
        console.log(err);
        this.openNotification(err.data);
      });
  };

  executeLoginAfterSignup = () => {
    $("#alert__back").hide();
    $("#preloader").fadeIn();

    const payLoad = {
      email: this.state.username,
      password: this.state.password,
      loggedInAs: "User",
    };
    Endpoint.login(payLoad)
      .then((res) => {
        console.log(res.data);
        //loginUser(res.data.data.accessToken, res.data.data, true);
        loginUser(res.data, res.data, false);
      })
      .catch((err) => {
        $("#preloader").fadeOut();
        this.openNotification(err.data.message);
      });
  };
  fetcthMenu = () => {
    Endpoint.getmenu()
      .then((res) => {
        console.log(res.data);
        let response = res.data;
        let filterFood = response.filter((x) => {
          return x.category == "Food";
        });
        let filterDrink = response.filter((x) => {
          return x.category == "Drink";
        });
        let filterShisha = response.filter((x) => {
          return x.category == "shisha";
        });
        let filterGrill = response.filter((x) => {
          return x.category == "grill";
        });
        let filterProtein = response.filter((x) => {
          return x.category == "protein";
        });
        let filterStarters = response.filter((x) => {
          return x.category == "starters";
        });
        console.log(filterFood, "food");
        console.log(filterDrink, "Drink");
        this.setState({
          foodArr: filterFood,
          drinkArr: filterDrink,
          shishaArr: filterShisha,
          proteinArr: filterProtein,
          grillArr: filterGrill,
          starterArr: filterStarters,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  componentDidMount() {
    //this.urlInitialization()
    //this.initPage();
    this.fetcthMenu();
    setTimeout(() => {
      // this.clearInputFields();
      $("#splash").hide();
      $("#loginScreen").fadeIn();
    }, 2000);
  }
  toggleRegisterCard = () => {
    $("#loginCard").hide();
    $("#registerCard").fadeIn(1500);
    // $("#create__btn").attr('disabled', true)
  };

  toggleLoginCard = () => {
    $("#registerCard").hide();
    $("#forgotCard").hide();
    $("#loginCard").fadeIn(1500);
  };

  toggleForgotPasswordCard = () => {
    $("#loginCard").hide();
    $("#forgotCard").fadeIn(1500);
    $("#recover__btn").attr("disabled", true);
  };

  toggleMenuCard = () => {
    $("#introscreen").hide();
    $("#loginScreenAlt").hide();

    $("#menuscreen").fadeIn();
  };
  toggleRatingScreen = () => {
    $("#introscreen").hide();
    $("#menuscreen").hide();
    $("#loginScreenAlt").hide();

    $("#ratingscreen").fadeIn();
  };
  toggleBack = () => {
    $("#menuscreen").hide();

    $("#ratingscreen").hide();

    $("#loginScreenAlt").hide();

    $("#introscreen").fadeIn();

  };

  toggleMoreRate = () => {
    $("#menuscreen").hide();
    $("#ratingscreen").hide();
    $("#introscreen").hide();
    $("#loginScreenAlt").fadeIn();
  };

  handleStar = (e) => {
    console.log(e);
    this.setState({
      starCount: e,
    });
  };
  submitStarRatings = () => {
    if (this.state.starCount <= 0) {
      alert("You have to rate before submitting!");
      return;
    }
    this.setState({ loading: true });
    const payLoad = {
      rates: this.state.starCount,
    };
    Endpoint.createRatings(null, payLoad.rates, null, null)
      .then((res) => {
        console.log(res);
        this.setState({ loading: false, starCount: 0 });
        // $("#star_count").val(0)
        // document.getElementById("star_count").value = 0
        alert("We have recieved your ratings. Thank you for patronizing us!");
        this.toggleBack();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  setSize = (data) => {
    console.log(data);
    // document.getElementById('drnk_sectio ').scrollIntoView();
    if (data === "food") {
      $("#drink_section").hide();
      $("#shisha").hide();
      $("#grill").hide();
      $("#protein").hide();
      $("#starters").hide();
      $("#food_section").fadeIn();
    } else if (data === "drinks") {
      $("#shisha").hide();
      $("#grill").hide();
      $("#protein").hide();
      $("#starters").hide();
      $("#food_section").hide();
      $("#drink_section").fadeIn();
    } else if (data === "shisha") {
      $("#drink_section").hide();
      $("#grill").hide();
      $("#protein").hide();
      $("#starters").hide();
      $("#food_section").hide();
      $("#shisha").fadeIn();
    } else if (data === "protein") {
      $("#shisha").hide();
      $("#grill").hide();
      $("#starters").hide();
      $("#food_section").hide();
      $("#drink_section").hide();
      $("#protein").fadeIn();
    } else if (data === "grill") {
      $("#shisha").hide();
      $("#starters").hide();
      $("#food_section").hide();
      $("#drink_section").hide();
      $("#protein").hide();
      $("#grill").fadeIn();
    } else if (data === "starters") {
      $("#shisha").hide();
      $("#food_section").hide();
      $("#drink_section").hide();
      $("#protein").hide();
      $("#grill").hide();
      $("#starters").fadeIn();
    } else if (data === "all") {
      $("#shisha").fadeIn();
      $("#food_section").fadeIn();
      $("#drink_section").fadeIn();
      $("#protein").fadeIn();
      $("#grill").fadeIn();
      $("#starters").fadeIn();
    }
  };
  render() {
    require("antd/dist/antd.css");

    return (
      <>
        <div id="alert__back">
          {/* <div id="status"> */}
          <center>
            <div
              style={{
                background: "#FFF",
                width: "100%",
                height: "354px",
                marginTop: "25vh",
                padding: "30px",
              }}
            >
              <img src={successAlert} />
              <p
                className="lato_700"
                style={{
                  color: "#5A020E",
                  fontSize: "21px",
                  lineHeight: "30px",
                  marginTop: "25px",
                  width: "70%",
                }}
              >
                Account created successfully
              </p>
              <a href="/betCategories">
                <button
                  // onClick={this.executeLoginAfterSignup}
                  className="btn btn-primary"
                  style={{
                    width: "100%",
                    height: "61px",
                    fontSize: "11px",
                    borderRadius: "5px",
                  }}
                >
                  Proceed
                </button>
              </a>
              <p style={{ fontSize: "11px", marginTop: "15px" }}>
                Thank you for using{" "}
                <span style={{ fontWeight: "bold" }}>MakeWeBet</span>
              </p>
            </div>
          </center>
          {/* <StageSpinner color="#FFB43A" backColor="#FFF" frontColor="#FFF" size={50} /> */}
          {/* </div> */}
        </div>

        <div id="preloader">
          <div id="status">
            <center>
              <img
                alt=""
                src={MWB_LOADER}
                style={{ width: "100px" }}
                className="lloyd-logo"
              />
            </center>
          </div>
        </div>

        {/* review */}
        <div
          style={{
            background: "#000000",
            width: "100%",
            height: "100vh",
            textAlign: "center",
            float: "left",
          }}
          className="splasho"
          id="introscreen"
        >
          {/* {this.state.splash ? ( */}
          <div className="mt-4" id="splash" style={{ display: "block" }}>
            <img
              alt=""
              src={splashLogo}
              style={{ width: "270px", marginTop: "250px" }}
            />
            <center>
              <StageSpinner
                color="#f1f1f1"
                backColor="#FFF"
                frontColor="#FFF"
                size={50}
              />
            </center>
          </div>
          {/* ) : null} */}
          {/* {this.state.loginScreen ?  */}
          <div className="container" id="" style={{ display: "none" }}>
            <div className="row">
              <div className="col-sm-12 text-center">
                <img
                  alt=""
                  src={splashLogo}
                  style={{ width: "70vw", marginTop: "50px" }}
                />
              </div>
            </div>
          </div>{" "}
          <div
            className="container"
            id="loginScreen"
            style={{ display: "none" }}
          >
            <div className="row">
              <div className="col-sm-12 text-center">
                <img
                  alt=""
                  src={splashLogo}
                  style={{ width: "70vw", marginTop: "50px" }}
                />
              </div>
            </div>

            <div className="row text-center justify-content-center">
              <div className="col-10">
                <button
                  onClick={() => this.toggleMenuCard()}
                  className="btn railway_light btn-outline-primary mt-5"
                  style={{ padding: "20px", width: "100%" }}
                >
                  Menu &nbsp;{" "}
                  <i style={{ color: "#ffc95d" }} className="fa fa-cutlery" />
                </button>
              </div>

              <div className="col-10">
                <button
                  onClick={() => this.toggleRatingScreen()}
                  className="btn btn-outline-primary mt-4 railway"
                  style={{ padding: "20px", width: "100%" }}
                >
                  Ratings &nbsp;{" "}
                  <i
                    style={{ color: "#ffc95d" }}
                    className="fa fa-star-half-o"
                  />
                </button>
              </div>
            </div>
          </div>{" "}
          {/* null} */}
          <small className="get__started1 poppins-400">
            Copyright 2022, All Rights Reserved
          </small>
        </div>

        {/* review */}
        <div
          style={{
            background: "#000000",
            width: "100%",
            float: "left",
            display: "none",
            minHeight: "100vh",
          }}
          className="splasho"
          id="loginScreenAlt"
        >
          <div style={{ padding: "20px" }}>
            <p
              onClick={this.toggleBack}
              className="text-white"
              style={{ fontWeight: "500" }}
            >
              <i className="fa fa-arrow-left" /> Back
            </p>
          </div>
          {/* {this.state.splash ? ( */}
          {/* <div className="mt-4" id="splash" style={{ display: 'block' }}>
                        <img alt="" src={splashLogo} style={{ width: "270px", marginTop: "250px" }} />
                        <center>
                            <StageSpinner color="#f1f1f1" backColor="#FFF" frontColor="#FFF" size={50} />
                        </center>
                    </div> */}
          {/* ) : null} */}
          {/* {this.state.loginScreen ?  */}
          <div className="container" id="">
            <div className="row">
              <div className="col-sm-12 text-center">
                <img
                  alt=""
                  src={splashLogo}
                  style={{ width: "70vw", marginTop: "20px" }}
                />
              </div>
            </div>
            <div className=" mt-2" id="morerate">
              <Card
                bordered={false}
                style={{
                  borderRadius: "25px",
                  height: "382px",
                  fontSize: "25px",
                }}
              >
                <p
                  style={{
                    fontSize: "12px",
                    marginTop: "-7px",
                    textAlign: "center",
                  }}
                  className="poppins-400"
                >
                  Tell us how you feel
                </p>
                <br />
                <div className="row" style={{ marginTop: "-35px" }}>
                  <div className="col-sm-3" style={{ width: "100%" }}>
                    <span
                      style={{
                        width: "14%",
                        borderRight: "1px solid #A6A6A6",
                        paddingRight: "20px",
                        lineHeight: "35px",
                        float: "left",
                      }}
                    >
                      <img alt="" src={profile} />
                    </span>{" "}
                    &nbsp;
                    <input
                      className="force-font-12"
                      placeholder="Fullname"
                      value={this.state.fullname}
                      name="fullname"
                      id="fname"
                      type="text"
                      style={{ width: "77%", border: "none", fontSize: "10px" }}
                      onChange={this.handleInput}
                    />
                    <hr style={{ marginTop: "10px" }} />
                  </div>
                </div>
                <div className="row" style={{ marginTop: "-35px" }}>
                  <div className="col-sm-3" style={{ width: "100%" }}>
                    {/* <span style={{ width: "14%", borderRight: "1px solid #A6A6A6", paddingRight: "20px", lineHeight: "35px", float: "left" }}>
                                            <img alt="" src={profile} />
                                        </span>{" "}
                                        &nbsp; */}
                    {/* <input className="force-font-12 mt-2" placeholder="Enter table number" value={this.state.tablenumber} name="tablenumber" id="tnumber" type="text" style={{ width: "77%", border: "none", fontSize: "10px" }} onChange={this.handleInput} />
                                        <hr style={{ marginTop: "10px" }} /> */}
                    <input
                      className="force-font-12"
                      placeholder="Enter table number"
                      value={this.state.tnumber}
                      name="tnumber"
                      type="text"
                      style={{ width: "77%", border: "none", fontSize: "10px" }}
                      onChange={this.handleInput}
                    />
                    <hr style={{ marginTop: "10px" }} />
                  </div>
                </div>
                <textarea
                  placeholder="Tell us how you feel..."
                  className="form-control"
                  value={this.state.textrating}
                  style={{
                    minHeight: "120px",
                    fontSize: "12px",
                    marginTop: "-20px",
                  }}
                  onChange={this.handleInput}
                  name={"textrating"}
                ></textarea>

                <div className="row" style={{ marginTop: "15px" }}>
                  <div className="col-sm-12">
                    <button
                      id="submit__btn"
                      className="btn btn-success poppinss"
                      size={"large"}
                      style={{
                        width: "100%",
                        padding: "16px",
                        borderRadius: "7px",
                        fontSize: "12px",
                        height: "61px",
                      }}
                      onClick={this.handleSubmit}
                      type="button"
                    >
                      {this.state.loading ? "Processing..." : "Submit"}{" "}
                      <i className="fa fa-send" />
                    </button>
                  </div>
                </div>
              </Card>
            </div>
          </div>{" "}
          {/* null} */}
          <small className="get__started1 poppins-400">
            Copyright 2022, All Rights Reserved
          </small>
        </div>

        {/* Menu */}
        <div
          style={{
            width: "100%",
            display: "none",
            minHeight: "100vh",
            float: "left",
            paddingRight: "46px",
          }}
          className="splashomenu"
          id="menuscreen"
        >
          <div className="" style={{ marginLeft: "-2px", marginRight: "-2px" }}>
            <p
              onClick={() => this.toggleBack()}
              className="text-white railway"
              style={{ fontWeight: "500", fontSize: "14px" }}
            >
              <i className="fa fa-arrow-left" /> Back
            </p>
            {/* <div className="container-fluid"> */}
            <center>
              <img
                alt=""
                src={logotrans}
                style={{ width: "70vw", marginTop: "-44px" }}
              />
            </center>
            {/* <div className="row justify-content-center">
                            <div className="col-3">
                                <p onClick={() => this.handleDrinkFocus()} style={{fontSize:"11px"}} className="text-white">All</p>
                                    
                            </div>
                            <div className="col-3">
                                <p onClick={() => this.handleDrinkFocus()} style={{fontSize:"11px"}} className="text-white">Food</p>

                            </div>

                            <div className="col-3">
                                <p onClick={() => this.handleDrinkFocus()} style={{fontSize:"11px"}} className="text-white">Drinks</p>

                            </div>
                        </div>
                         */}
            <center style={{ marginTop: "-24px" }}>
              <Radio.Group
                defaultValue={"all"}
                onChange={(e) => this.setSize(e.target.value)}
              >
                <Radio.Button value="all">All</Radio.Button>
                <Radio.Button value="food">Main Dishes&nbsp;</Radio.Button>
                <Radio.Button value="shisha">
                  Cocktails/Shisha & Cigarettes&nbsp;
                </Radio.Button>
                <Radio.Button value="protein">
                  Protein & Sides&nbsp;
                </Radio.Button>
                <Radio.Button value="grill">Grill Zone&nbsp;</Radio.Button>
                <Radio.Button value="starters">Starters&nbsp;</Radio.Button>
                <Radio.Button value="drinks">Drinks&nbsp;</Radio.Button>
              </Radio.Group>
            </center>
            <div className="row mt-4">
              <div className="col-12" id="food_section">
                <div className="row">
                  <div className="col-12">
                    <p
                      className="text-white text-left railway_thick text-center"
                      style={{ fontWeight: "600", fontSize: "16px" }}
                    >
                      Main Dishes
                    </p>
                  </div>
                </div>
                {this.state.foodArr &&
                  this.state.foodArr.map((x, i) => {
                    return (
                      <div className="row" style={{ marginTop: "-5px" }}>
                        <div className="col-10">
                          <p
                            style={{ fontSize: "12px", lineHeight: "normal" }}
                            className="text-white railway wrp"
                          >
                            {x.name}
                          </p>
                        </div>

                        <div className="col-2">
                          <p
                            style={{ fontSize: "12px" }}
                            className="text-white railway"
                          >
                            {x.amount > 0 ? nairaFormat(x.amount, 0) : "-"}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className="col-12" id="grill">
                <div className="row">
                  <div className="col-12">
                    <p
                      className="text-white text-left railway_thick text-center"
                      style={{ fontWeight: "600", fontSize: "16px" }}
                    >
                      Grill Zone
                    </p>
                  </div>
                </div>
                {this.state.grillArr &&
                  this.state.grillArr.map((x, i) => {
                    return (
                      <div className="row" style={{ marginTop: "-5px" }}>
                        <div className="col-10">
                          <p
                            style={{ fontSize: "12px", lineHeight: "normal" }}
                            className="text-white railway wrp"
                          >
                            {x.name}
                          </p>
                        </div>

                        <div className="col-2">
                          <p
                            style={{ fontSize: "12px" }}
                            className="text-white railway"
                          >
                            {x.amount > 0 ? nairaFormat(x.amount, 0) : "-"}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className="col-12" id="protein">
                <div className="row">
                  <div className="col-12">
                    <p
                      className="text-white text-left railway_thick text-center"
                      style={{ fontWeight: "600", fontSize: "16px" }}
                    >
                      Protein & Sides
                    </p>
                  </div>
                </div>
                {this.state.proteinArr &&
                  this.state.proteinArr.map((x, i) => {
                    return (
                      <div className="row" style={{ marginTop: "-5px" }}>
                        <div className="col-10">
                          <p
                            style={{ fontSize: "12px", lineHeight: "normal" }}
                            className="text-white railway wrp"
                          >
                            {x.name}
                          </p>
                        </div>

                        <div className="col-2">
                          <p
                            style={{ fontSize: "12px" }}
                            className="text-white railway"
                          >
                            {x.amount > 0 ? nairaFormat(x.amount, 0) : "-"}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="col-12" id="shisha">
                <div className="row">
                  <div className="col-12">
                    <p
                      className="text-white text-left railway_thick text-center"
                      style={{ fontWeight: "600", fontSize: "16px" }}
                    >
                      Cocktails, Shisha &. Cigarettes
                    </p>
                  </div>
                </div>
                {this.state.shishaArr &&
                  this.state.shishaArr.map((x, i) => {
                    return (
                      <div className="row" style={{ marginTop: "-5px" }}>
                        <div className="col-10">
                          <p
                            style={{ fontSize: "12px", lineHeight: "normal" }}
                            className="text-white railway wrp"
                          >
                            {x.name}
                          </p>
                        </div>

                        <div className="col-2">
                          <p
                            style={{ fontSize: "12px" }}
                            className="text-white railway"
                          >
                            {x.amount > 0 ? nairaFormat(x.amount, 0) : "-"}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className="col-12" id="starters">
                <div className="row">
                  <div className="col-12">
                    <p
                      className="text-white text-left railway_thick text-center"
                      style={{ fontWeight: "600", fontSize: "16px" }}
                    >
                      Starters
                    </p>
                  </div>
                </div>
                {this.state.starterArr &&
                  this.state.starterArr.map((x, i) => {
                    return (
                      <div className="row" style={{ marginTop: "-5px" }}>
                        <div className="col-10">
                          <p
                            style={{ fontSize: "12px", lineHeight: "normal" }}
                            className="text-white railway wrp"
                          >
                            {x.name}
                          </p>
                        </div>

                        <div className="col-2">
                          <p
                            style={{ fontSize: "12px" }}
                            className="text-white railway"
                          >
                            {x.amount > 0 ? nairaFormat(x.amount, 0) : "-"}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="col-12" id="drink_section">
                <div className="row">
                  <div className="col-12">
                    <p
                      className="text-white text-left railway_thick text-center"
                      style={{ fontWeight: "600", fontSize: "16px" }}
                    >
                      Drinks
                    </p>
                  </div>
                </div>
                {this.state.foodArr &&
                  this.state.drinkArr.map((x, i) => {
                    return (
                      <div className="row" style={{ marginTop: "-11px" }}>
                        <div className="col-9">
                          <p
                            style={{ fontSize: "12px" }}
                            className="text-white railway wrp"
                          >
                            {x.name}
                          </p>
                        </div>

                        <div className="col-3">
                          <p
                            style={{ fontSize: "12px" }}
                            className="text-white railway"
                          >
                            {x.amount > 0 ? nairaFormat(x.amount, 0) : "-"}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className="col-12 mt-5" id="">
                <div className="row">
                  <div className="col-12"></div>
                </div>
              </div>
            </div>

            {/* </div> */}
          </div>
        </div>

        <div
          style={{
            width: "100%",
            display: "none",
            height: "100vh",
            float: "left",
          }}
          className="splashomenualt"
          id="ratingscreen"
        >
          <p onClick={this.toggleBack} className="text-white railway_light">
            <i className="fa fa-arrow-left" /> Back
          </p>
          <div className="container-fluid" style={{ overflowY: "scroll" }}>
            <div>
              <p
                className="text-white mt-5 railway_thick"
                style={{ fontWeight: "400", fontSize: "20px" }}
              >
                Ratings
              </p>
              <div className="container">
                <center className="mt-5">
                  <div className="row">
                    <div className="col-12">
                      <p className="text-white railway_light">
                        Rate your overall experience
                      </p>
                      <Rate
                        id="star_count"
                        allowClear
                        value={this.state.starCount}
                        style={{ fontSize: "20px" }}
                        onChange={(e) => this.handleStar(e)}
                      />
                    </div>
                    <div className="col-12">
                      <button
                        onClick={this.submitStarRatings}
                        className="btn mt-4 btn-sm railway-light"
                      >
                        {" "}
                        {this.state.loading ? "Loading..." : "Submit Ratings"}
                        <i className="fa fa-send" />
                      </button>
                    </div>
                  </div>
                </center>
              </div>
            </div>

            <hr />

            <div className="row mt-9">
              <div className="col-12 mt-6">
                {/* <div className="form-group">
                                    <textarea placeholder="Tell us how you feel..." className="form-control">

                                    </textarea>

                                </div> */}
                <button
                  type="button"
                  onClick={this.toggleMoreRate}
                  className="btn btn-success col-12"
                >
                  Want to tell us more? <i className="fa fa-arrow-right" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Home;