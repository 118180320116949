import React from "react";
import { stateKeys } from "../redux/actions";
import { reduxState, setReduxState } from "./helpers";
import { Navigate } from "react-router-dom";
import Endpoint from "./endpoint";
//import store from "../redux/store"
//import { Navigate } from "react-router";
//import {browserHistory, hi} from "react-router";

export function __RouteProps(route) {
  //useHistory.push(route);
}
const TOKEN_KEY = "token";
export const USER_KEY = stateKeys.USER;

export function userLoggedIn() {
  return !!getUserToken();
}

export function getActiveStore() {
  return sessionStorage.getItem(USER_KEY) ? sessionStorage : localStorage;
}
// export function getActiveStore() {
//     return sessionStorage.getItem(TOKEN_KEY) ? sessionStorage : localStorage;
// }
export async function loadUserWalletDetails(userId) {
  Endpoint.loadUserWalletBalance(userId)
    .then((res) => {
      localStorage.setItem(stateKeys.SERVICES, JSON.stringify(res.data));
    })
    .catch((err) => {});
}

export function loginUser(token, user, redirect) {
  const storage = localStorage;
  const _storage = sessionStorage;
  storage.setItem(TOKEN_KEY, token);
  _storage.setItem(TOKEN_KEY, token);
  if (user) {
    storage.setItem(stateKeys.USER, JSON.stringify(user));

    if (redirect) {
      const intended = rememberRoute();
      if (intended) {
        window.location = intended;
      } else if (user != null) {
        loadUserWalletDetails(user.userInfo.userId);
        setReduxState(user, stateKeys.USER);
        setTimeout(() => {
          reduxState(user, stateKeys.USER);
        }, 2000);
        window.location = "/dashboard";
      } else {
        return false;
      }
    } else {
      setReduxState(user, stateKeys.USER);
      setTimeout(() => {
        reduxState(user, stateKeys.USER);
      }, 2000);
      //window.location = "/betCategories";
    }
  }
}

// export function loginUser(token, user, redirect) {
//     const storage = localStorage;
//     const _storage = sessionStorage;
//     storage.setItem(TOKEN_KEY, token);
//     _storage.setItem(TOKEN_KEY, token);
//     if (user) {
//         storage.setItem(stateKeys.USER, JSON.stringify(user));

//         if (redirect) {
//             const intended = rememberRoute();
//             if (intended) {
//                 window.location = intended;
//             } else if (user.tokenIdentity != null) {
//                 setReduxState(user.userInfo, stateKeys.USER);
//                 setTimeout(() => {
//                     reduxState(user, stateKeys.USER);
//                 }, 2000);
//                 window.location = "/dashboard";
//             } else {
//                 return false;
//             }
//         } else {
//             setReduxState(user.userInfo, stateKeys.USER);
//             setTimeout(() => {
//                 reduxState(user, stateKeys.USER);
//             }, 2000);
//             window.location = "/betCategories";
//         }
//     }
// }

export function RerouteActiveUser() {
  const user = JSON.parse(localStorage.getItem(stateKeys.USER));

  if (user) {
    //storage.setItem("_IDENTITY_", JSON.stringify(user));

    if (user.role == "User") {
      window.location = "/profile";
      return true;
    } else if (user == "superteller@kulpay") {
      window.location = "/superteller/index";
      return true;
    } else {
      return false;
    }
  }
}
export function getUserToken() {
  return getActiveStore().getItem(TOKEN_KEY);
}

export function loadUserInfo() {
  const data = getActiveStore().getItem(stateKeys.USER);
  const user = data ? JSON.parse(data) : null;
  setReduxState(user, stateKeys.USER);
  return user;
}

export function getUser(key, defaultValue = null) {
  const userData = getActiveStore().getItem(stateKeys.USER);
  let data = userData ? JSON.parse(userData) : null;

  if (!data || (key && typeof data[key] === "undefined")) {
    return defaultValue;
  }

  return key ? data[key] : data;
}

export function updateUserInfo(data) {
  const userData = getUser();
  let update = Object.assign({}, userData, data);

  getActiveStore().setItem(stateKeys.USER, JSON.stringify(update));
  setReduxState(update, stateKeys.USER);
}

// export const AuthRoute = withRouter(({component: Component, path, authorized, ...rest}) => {

//     return <Navigate from={`/signin`} to={path}/>

// });

export function rememberRoute() {
  const key = "__intended";
  const old = sessionStorage.getItem(key);
  // sessionStorage.setItem(key, window.location.pathname);

  return old;
}

export function logOutUser(Navigate) {
  // getActiveStore().removeItem(TOKEN_KEY);
  localStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem(USER_KEY);
  localStorage.removeItem(USER_KEY);
  localStorage.removeItem(stateKeys.SERVICES);
  // localStorage.removeItem(stateKeys.USER);
  // sessionStorage.removeItem(stateKeys.USER);

  window.location = Navigate ? Navigate : "/";
}
export function clearStore() {
  // getActiveStore().removeItem(TOKEN_KEY);
  localStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem(USER_KEY);
  localStorage.removeItem(USER_KEY);
  localStorage.removeItem(stateKeys.SERVICES);

  // localStorage.removeItem(stateKeys.USER);
  // sessionStorage.removeItem(stateKeys.USER);
}
