import React, { Component } from "react"
import MWB_LOADER from "../../assets/images/mwb_loader.png"
import splashLogo from "../../assets/images/rewindlogo.png"
import successAlert from "../../assets/images/successAlert.svg";
import { StageSpinner } from "react-spinners-kit";
import { Card, notification, Rate } from "antd";
import $ from "jquery";
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";
import { userLoggedIn, loginUser, USER_KEY } from "../../utils/auth";
import Endpoint from "../../utils/endpoint";
import { Link } from "react-router-dom";


class AdminIndex extends Component {
    state = {
        payLoad: JSON.parse(localStorage.getItem(USER_KEY)),
        foodArr: [],
        drinkArr: [],
        starCount:0
    }

    openNotification = (err) => {
        notification.error({
            message: <small className="poppins-600">Error</small>,
            description: <small>{err}</small>,
            placement: 'topRight'
        });
    };
    clearInputFields() {
        document.getElementById("username").value = "";
        document.getElementById("password").value = "";
        this.setState({ pageLoading: false });
    }
    InitializeUser = () => {
        if (userLoggedIn()) {
            $("#preloader").fadeIn()
            setTimeout(() => {
                window.location.href = "/dashboard"
            }, 1000);
            // loginUser(this.state.payLoad?.accessToken, this.state.payLoad, true);
        } else {
            $("#submit__btn").attr("disabled", true)
        }
    };
    urlInitialization = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const emailParams = urlParams.get('email')
        if (emailParams != null) {
            this.setState({
                email: emailParams,
                triggerDisabled: true
            })
            $("#loginCard").hide()
            $("#registerCard").fadeIn(1500)
        }


    }
    handleInput = (event) => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });

        this.validateInputFields();
    };

    validateInputFields = () => {
        if ((this.state.username != null && this.state.username.length >= 4) && (this.state.password != null && this.state.password.length >= 4)) {
            $("#submit__btn").attr("disabled", false)
        }
        else {
            $("#submit__btn").attr("disabled", true)
        }
    }
    initPage = () => {
        setTimeout(() => {
            $("#splash").fadeOut();
        }, 3000);

        // setTimeout(() => {
        //     $("#loginScreen").fadeIn("slow");
        //     $("#submit__btn").attr("disabled", true)

        // }, 3500);
    }
    handleSubmitAlt = () => {
        if (this.state.username == null && this.state.password == null) {
            this.openNotification("Enter username and password");
            return
        }
        $("#preloader").fadeIn()
        const payLoad = {
            "email": this.state.username,
            "password": this.state.password,
            "loggedInAs": "User"
        }
        Endpoint.login(payLoad)
            .then((res) => {
                console.log(res)
                console.log(res.data.jwtRes.statusCode)
                //     if(res.data.jwtRes.statusCode === 400){
                //         $("#preloader").fadeOut()
                //        this.openNotification(res.data.data.jwtRes.message);
                //         return
                //    }
                loginUser(res.data.accessToken, res.data, true);
                // loginUser(res.data, res.data, true);
            })
            .catch((err) => {
                console.log(err)
                $("#preloader").fadeOut()
                this.openNotification(err.data);
            })

    }
    handleSubmit = () => {
        if(this.state.textrating == null){
            alert("Input text!")
            return
        }
        this.setState({loading:true})
        const payLoad = {
            Ratings: this.state.textrating,
            Name: this.state.fullname
        }
        Endpoint.createRatings(payLoad)
        .then((res) => {
            console.log(res)
        this.setState({loading:false})
        alert("We have recieved your comments. Thank you for patronizing us!")
        window.location.reload(true)
        })
        .catch((err) => {
            console.log(err)
        this.setState({loading:false})

        })
    }
    handleSubmitAlt = () => {
        if (this.state.username == null && this.state.password == null) {
            this.openNotification("Enter username and password");
            return
        }
        $("#preloader").fadeIn()
        const payLoad = {
            "email": this.state.username,
            "password": this.state.password,
            "loggedInAs": "User"
        }
        Endpoint.login(payLoad)
            .then((res) => {
                console.log(res.data)
                //loginUser(res.data.data.accessToken, res.data.data, true);
                loginUser(res.data, res.data, true);
            })
            .catch((err) => {
                $("#preloader").fadeOut()
                this.openNotification(err.data.message);
            })

    }
    handleCreateAccount = () => {
        if (this.state.username == null || this.state.password == null || this.state.phone_number == null || this.state.email == null) {
            this.openNotification("Fields presented are not to be left empty");
            return
        }
        $("#preloader").fadeIn()
        const payLoad = {
            "username": this.state.username,
            "email": this.state.email,
            "password": this.state.password,
            "phoneNumber": this.state.phone_number
        }
        console.log(this.state)
        Endpoint.signup(payLoad)
            .then((res) => {
                console.log(res.data)
                $("#preloader").fadeOut()
                $("#alert__back").fadeIn();
                this.setState({
                    resPayload: res.data
                })

                loginUser(res.data.accessToken, res.data, false);
            })
            .catch((err) => {
                $("#preloader").fadeOut()
                console.log(err)
                this.openNotification(err.data);
            })
    }

    executeLoginAfterSignup = () => {
        $("#alert__back").hide();
        $("#preloader").fadeIn()

        const payLoad = {
            "email": this.state.username,
            "password": this.state.password,
            "loggedInAs": "User"
        }
        Endpoint.login(payLoad)
            .then((res) => {
                console.log(res.data)
                //loginUser(res.data.data.accessToken, res.data.data, true);
                loginUser(res.data, res.data, false);
            })
            .catch((err) => {
                $("#preloader").fadeOut()
                this.openNotification(err.data.message);
            })
    }
 
    componentDidMount() {
        // this.initPage();
        setTimeout(() => {
            $("#splash").hide()
            $("#loginScreen").fadeIn()
        }, 2000);
    }
    toggleRegisterCard = () => {
        $("#loginCard").hide()
        $("#registerCard").fadeIn(1500)
        // $("#create__btn").attr('disabled', true)

    }

    toggleLoginCard = () => {
        $("#registerCard").hide()
        $("#forgotCard").hide()
        $("#loginCard").fadeIn(1500)
    }

    toggleForgotPasswordCard = () => {
        $("#loginCard").hide()
        $("#forgotCard").fadeIn(1500)
        $("#recover__btn").attr('disabled', true)
    }

    toggleMenuCard = () => {
        $("#introscreen").hide()
        $("#loginScreenAlt").hide()

        $("#menuscreen").fadeIn()
    }
    toggleRatingScreen = () => {
        $("#introscreen").hide()
        $("#menuscreen").hide()
        $("#loginScreenAlt").hide()

        $("#ratingscreen").fadeIn()
    }
    toggleBack = () => {
        $("#menuscreen").hide()
        $("#ratingscreen").hide()
        $("#loginScreenAlt").hide()
        $("#introscreen").fadeIn()
    }

    toggleMoreRate = () => {
        $("#menuscreen").hide()
        $("#ratingscreen").hide()
        $("#introscreen").hide()
        $("#loginScreenAlt").fadeIn()
    }
    
    handleStar = (e) => {
        console.log(e)
        this.setState({
            starCount: e
        })
    }
    submitStarRatings = () => {
        if(this.state.starCount <= 0){
            alert("You have to rate before submitting!")
            return
        }
        this.setState({loading:true})
        const payLoad = {
            rates: this.state.starCount
        }
        Endpoint.createRatings(payLoad)
        .then((res) => {
            console.log(res)
        this.setState({loading:false})
        alert("We have recieved your ratings. Thank you for patronizing us!")
        })
        .catch((err) => {
            console.log(err)
        this.setState({loading:false})

        })
    }
    render() {
        require("antd/dist/antd.css");

        return (
            <>
                <div id="alert__back">
                    {/* <div id="status"> */}
                    <center>
                        <div style={{ background: "#FFF", width: "100%", height: "354px", marginTop: "25vh", padding: "30px" }}>
                            <img src={successAlert} />
                            <p className="lato_700" style={{ color: "#5A020E", fontSize: "21px", lineHeight: "30px", marginTop: "25px", width: "70%" }}>
                                Account created successfully
                            </p>
                            <a href="/betCategories">
                                <button
                                    // onClick={this.executeLoginAfterSignup} 
                                    className="btn btn-primary" style={{ width: "100%", height: "61px", fontSize: "11px", borderRadius: "5px" }}>
                                    Proceed
                                </button>
                            </a>
                            <p style={{ fontSize: "11px", marginTop: "15px" }}>
                                Thank you for using <span style={{ fontWeight: "bold" }}>MakeWeBet</span>
                            </p>
                        </div>

                    </center>
                    {/* <StageSpinner color="#FFB43A" backColor="#FFF" frontColor="#FFF" size={50} /> */}
                    {/* </div> */}
                </div>


                <div id="preloader">
                    <div id="status">
                        <center>
                            <img alt="" src={MWB_LOADER} style={{ width: '100px' }} className="lloyd-logo" />

                        </center>
                    </div>
                </div>

                {/* review */}
                <div style={{ background: "#000000", width: "100%", height: "100vh", textAlign: "center", float: "left" }} className="splasho" id="introscreen">
                    {/* {this.state.splash ? ( */}
                    <div className="mt-4" id="splash">
                        <img alt="" src={splashLogo} style={{ width: "270px", marginTop: "250px" }} />
                        <center>
                            <StageSpinner color="#f1f1f1" backColor="#FFF" frontColor="#FFF" size={50} />
                        </center>
                    </div>
                    {/* ) : null} */}
                    {/* {this.state.loginScreen ?  */}
                    <div className="container" id="" style={{ display: "none" }}>
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <img alt="" src={splashLogo} style={{ width: "70vw", marginTop: "50px" }} />
                            </div>
                        </div>
                     
                       
                    </div>{" "}

                      <div className="container" id="loginScreen" style={{ display: "none" }}>
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <img alt="" src={splashLogo} style={{ width: "70vw", marginTop: "50px" }} />
                            </div>
                            <div className="col-sm-12 text-center">
                                <p className="text-white railway-light">Hi Admin!</p>
                            </div>
                        </div>
                            
                     <div className="row text-center justify-content-center">
                         
                         <div className="col-10">
                            <Link to={"/addmenu"} className="btn railway_light btn-outline-primary mt-5" style={{ padding: "20px", width:"100%"}}>
                                Menu Management &nbsp; <i style={{color:"#ffc95d"}} className="fa fa-wrench" />
                            </Link>
                        </div>
                        
                            <div className="col-10">
                            <Link to={"/viewcomm_ent_s"} className="btn btn-outline-primary mt-4 railway_light" style={{ padding: "20px", width:"100%" }}>
                                View Comments &nbsp; <i style={{color:"#ffc95d"}} className="fa fa-comments" />
                            </Link>
                            </div>

                            <div className="col-10">
                            <Link to={"/adminratings"} className="btn btn-outline-primary mt-4 railway_light" style={{ padding: "20px", width:"100%" }}>
                                View Ratings &nbsp; <i style={{color:"#ffc95d"}} className="fa fa-star-half-o" />
                            </Link>
                            </div>
                         </div>
                       
                    </div>{" "}
                    {/* null} */}
                </div>



          
            </>
        )
    }
}

export default AdminIndex;