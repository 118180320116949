import axios from "axios";
import { notify, objectToHTTPQuery } from "./helpers";
import { getUserToken, logOutUser, rememberRoute } from "./auth";

let headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  token: localStorage.getItem("token"),
};

let fileHeaders = {
  Accept: "application/json",
  "Content-Type": "multipart/form-data",
  token: localStorage.getItem("token"),
};

const Endpoint = {
  init: () => {
    // accountId = process.env.REACT_APP_ACCOUNT_ID;
    let token = getUserToken();
    if (token)
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    //axios.defaults.baseURL = "http://10.211.55.3/api";
    //axios.defaults.baseURL = "https://c5-na.altogic.com/e:633d621b0a58642ae6f922a6";
    axios.defaults.baseURL =
      "https://backendyabatechelearning.lloydant.com/api";

    // Intercept 401 HTTP Error code in API
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (!error.response) {
          //No response
          // notify("Seems like you're offline, check internet connection")
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.config.url !== "/"
        ) {
          rememberRoute();
          logOutUser();
        }

        return Promise.reject(error.response);
      }
    );
  },

  // ---Auth--- //
  // login: (data) => {
  //     return axios.post(`/Auth/login`, data, headers)
  // },
  // signup: (data) => {
  //     return axios.post(`/Auth/signup`, data, headers)
  // },

  getmenu: () => {
    return axios.get(`/RWLog/GetAllLog`, headers);
  },
  createRatings: (name, rates, comments, table) => {
    return axios.post(
      `/RWLog/AddRatings?name=${name}&rates=${rates}&comments=${comments}&table=${table}`,
      headers
    );
  },
  createMenuItem: (data) => {
    return axios.post(
      `/RWLog/AddLog?name=${data.Name}&category=${data.Category}&amount=${data.Amount}`,
      headers
    );
  },
  deleteMenuItem: (data) => {
    return axios.post(`/RWLog/DeleteLog?id=${data}`, headers);
  },
  getRatings: () => {
    return axios.get(`/RWLog/GetAllRatings`, headers);
  },

  // ---Auth- default-- //

  login: (data) => {
    return axios.post(`/Auth/signin`, data, headers);
  },
  signup: (data) => {
    return axios.post(`/Auth/signup`, data, headers);
  },

  createBet: (data) => {
    return axios.post(`/Bet/CreateBet`, data, headers);
  },
  getUnpublishedBets: (data) => {
    return axios.get(`/Bet/GetUserUnpublishedBets?userId=${data}`, headers);
  },
  userWalletStatus: (data) => {
    return axios.get(`/UserWallet/UserWalletStatus?userId=${data}`, headers);
  },
  getBetCategories: () => {
    return axios.get(`/BetCategory/ActiveCategories`, headers);
  },
  getPopularUsers: () => {
    return axios.get(`/User/PopularUsers`, headers);
  },

  addUserBetCategory: (userId, data) => {
    return axios.post(
      `/UserBetCategory/AddUserCategory?userId=${userId}`,
      data,
      headers
    );
  },

  multipleUserFollow: (userId, data) => {
    return axios.post(
      `/UserFollowership/MultipleFollowUser?userId=${userId}`,
      data,
      headers
    );
  },

  getUserBetFeed: (userId) => {
    return axios.get(
      `/UserBetCategory/GetUserBetFeed?userId=${userId}`,
      headers
    );
  },
  getUserFollwers: (userId) => {
    return axios.get(
      `/UserFollowership/GetUserFolloweshipList?userId=${userId}`,
      headers
    );
  },
  getBetInvittation: (userId) => {
    return axios.get(
      `/UserBetCategory/FetchUserBetInvitations?userId=${userId}`,
      headers
    );
  },
  acceptOrDeclineBetInvitation: (betInvitationId, action) => {
    return axios.post(
      `/BetInvitation/UpdateInvitationActionStatus?betInvitationId=${betInvitationId}&actionType=${action}`,
      headers
    );
  },
  createBetPair: (data) => {
    return axios.post(`/UserBet/PostUserBetPairing`, data, headers);
  },
  getPlayersWaiting: (betId) => {
    return axios.get(`/Bet/GetAllBetPlayers?betId=${betId}`, headers);
  },
  getBetByBetId: (betId) => {
    return axios.get(`/UserBetCategory/GetBetById?betId=${betId}`, headers);
  },
  getBetByBetCode: (betcode) => {
    return axios.get(
      `/UserBetCategory/GetBetByBetCode?betCode=${betcode}`,
      headers
    );
  },
  inviteExternalUser: (data) => {
    return axios.post(`/IncubatorUser/InviteUserViaEmail`, data, headers);
  },
  getUserProfile: (userId) => {
    return axios.get(`/Auth/GetUserProfile?userId=${userId}`, headers);
  },
  postUserProfileUpdate: (userId, data) => {
    return axios.post(
      `/Auth/UpdateUserProfile?userId=${userId}`,
      data,
      headers
    );
  },
  saveBetToWatchlist: (userId, betId) => {
    return axios.post(
      `/Bet/AddToWatchlist?userId=${userId}&betId=${betId}`,
      headers
    );
  },
  fetchUserWatchlist: (data) => {
    return axios.get(
      `/UserBetCategory/FetchUserWatchlist?userId=${data}`,
      headers
    );
  },
  getUserLikesLabel: (data) => {
    return axios.get(`/Bet/BetUserLikes?userId=${data}`, headers);
  },
  postLikeBet: (userId, betId) => {
    return axios.post(`/Bet/Likebet?userId=${userId}&betId=${betId}`, headers);
  },
  getUserWatchlistLabel: (data) => {
    return axios.get(`/Bet/WatchlistReturnArr?userId=${data}`, headers);
  },
  removeLikedBet: (userId, betId) => {
    return axios.post(
      `/Bet/LikebetDelete?userId=${userId}&betId=${betId}`,
      headers
    );
  },
  removeWatchlistBet: (userId, betId) => {
    return axios.post(
      `/Bet/WatchlistBetDelete?userId=${userId}&betId=${betId}`,
      headers
    );
  },

  //Payment
  intializeWalletFunding: (data) => {
    return axios.post(`/Payment/InitializeTransaction`, data, headers);
  },
  intializePaymentUpdate: (data) => {
    return axios.post(`/Payment/UpdatePayment?reference=${data}`, headers);
  },
  fetchUserTransactions: (data) => {
    return axios.get(`/Payment/GetTransactionUser?userId=${data}`, headers);
  },
};

export default Endpoint;
